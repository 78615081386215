import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import Root from "./views/root";
import Header from "./components/Header";
import WhyRegister from "./views/whyRegister";
import AboutUs from "./views/aboutUs";
import Clients from "./views/clients";
import QualityReference from "./views/qualityReference";
import BePartner from "./views/bePartner";
import StepsToRegister from "./views/stepsToRegister";
import Whatsapp from "./views/whatsApp";
import DoubtsSolver from "./views/doubtsSolver";
import Schedule from "./views/schedule";
import Footer from "./views/footer";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Header />
    <Root />
    <Whatsapp />
    <WhyRegister />
    <AboutUs />
    <QualityReference />
    <Clients />
    <StepsToRegister />
    {/* <DoubtsSolver /> */}
    <BePartner />
    {/* <Schedule /> */}
    <Footer />
    <ToastContainer
      position="top-right"
      autoClose={2000}
      className={"z-20"}
      theme="colored"
    />
  </>
);
