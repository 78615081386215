import React from "react";
import config from "../assets/index.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { GiMagnifyingGlass } from "react-icons/gi";
import { RiFilePaper2Line } from "react-icons/ri";
import { WiTime4 } from "react-icons/wi";
import { FaCheck } from "react-icons/fa6";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";

const icons = {
  glassIcon: <GiMagnifyingGlass className="flex w-full h-full" />,
  clockIcon: <WiTime4 className="flex w-full h-full" />,
  paperIcon: <RiFilePaper2Line className="flex w-full h-full" />,
  checkIcon: <FaCheck className="flex w-full h-full" />,
};

const StepsToRegister = () => {
  const { stepsToRegister } = config;
  const steps = () => {
    return stepsToRegister.map((items, index) => {
      return (
        <SwiperSlide className="p-2 sm:p-10 h-full w-full" key={index}>
          <div
            className="flex justify-center items-center w-full h-20"
            key={index}
          >
            <b className="h-8 sm:h-14 text-center text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
              {items.title}
            </b>
          </div>
          <div className="h-full w-full mt-10">
            {items.content.map((content, secondIndex) => {
              const col = secondIndex % 2 === 0 ? 1 : 2;
              return (
                <div
                  className="grid grid-cols-2 grid-rows-auto h-[50vh] sm:h-[70vh] p-2 sm:p-10"
                  key={content.step}
                >
                  <div
                    className={`flex w-full justify-center items-center relative col-start-${col}`}
                  >
                    <div className="w-full sm:w-3/4 flex flex-col items-center justify-center bg-secondary rounded-[50px] h-full">
                      <div className="flex flex-col justify-start items-center h-full">
                        <div className="w-1/2 sm:w-1/3 h-14 flex items-center justify-center rounded-full bg-tertiary absolute top-[-25px]">
                          <b className="text-white text-center font-Inter text-sm lg:text-2xl sm:w-72">
                            {content.step}
                          </b>
                        </div>
                        <div className="flex h-full w-full flex-col">
                          <div className=" mt-10 flex justify-center items-center ">
                            <div className="h-10 w-10 sm:h-20 sm:w-20 text-white">
                              {icons[content.icon]}
                            </div>
                          </div>
                          <div className="">
                            <p className="text-fourth text-center font-Poppins text-sm sm:text-xl font-center w-48 sm:w-80 mt-4 sm:mt-8 p-4 sm:p-0">
                              {content.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </SwiperSlide>
      );
    });
  };
  return (
    <>
      <Swiper
        className="w-full h-full bg-white flex flex-col"
        spaceBetween={0}
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
        }}
        name={stepsToRegister[0].href}
        style={{ zIndex: 0 }}
      >
        {steps()}
      </Swiper>
    </>
  );
};

export default StepsToRegister;
