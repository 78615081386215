import React from "react";

import config from "../assets/index.json";
import { FaWhatsapp } from "react-icons/fa";

const icon = {
  whatsappIcon: <FaWhatsapp className="flex w-full h-full" />,
};

const App = () => {
  const { whatsapp } = config;
  return (
    <div className="fixed w-14 h-14 sm:w-16 sm:h-16 bottom-6 right-6 sm:bottom-10 sm:right-10 z-50 flex items-center justify-center bg-[#25d366] rounded-full border-1">
      <a
        href={whatsapp.href}
        target="_blank"
        rel="noreferrer"
        className="no-underline"
      >
        <div className="flex justify-center items-center ">
          <div className="h-10 w-10 text-white">{icon[whatsapp.icon]}</div>
        </div>
      </a>
    </div>
  );
};

export default App;
