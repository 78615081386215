import React from "react";
import config from "../assets/index.json";
import { RiFilePaper2Line } from "react-icons/ri";
import { MdOutlineComputer } from "react-icons/md";
import { WiTime4 } from "react-icons/wi";

const icons = {
  parchmentIcon: <RiFilePaper2Line className="flex w-full h-full" />,
  computerIcon: <MdOutlineComputer className="flex w-full h-full" />,
  agilityIcon: <WiTime4 className="flex w-full h-full" />,
};

const QualityReference = () => {
  const { qualityReference } = config;
  const { title, href, content, payment } = qualityReference;
  const qualities = () => {
    return content.map((items, index) => {
      return (
        <div className="px-1 sm:px-2 h-[60vh] sm:h-[80vh]" key={index}>
          <div className="w-full flex flex-col items-center justify-start bg-tertiary rounded-lg h-full">
            <div className="mt-4 sm:mt-10 flex justify-center items-center ">
              <div className="h-10 w-10 sm:h-20 sm:w-20 text-white">
                {icons[items.icon]}
              </div>
            </div>
            <b className="text-fourth text-center font-Inter text-sm lg:text-2xl border-b border-b-2 border-fourth sm:w-72">
              {items.title}
            </b>
            <div className="mt-2 p-4 sm:p-10">
              <p className="text-fourth text-center font-Inter text-xs sm:text-lg sm:w-72">
                {items.description}
              </p>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="w-full h-full bg-white flex flex-col" name={href}>
      <div className="flex justify-center items-center w-full h-20">
        <b className="h-8 sm:h-14 text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary text-center flex justify-center items-center">
          {title}
        </b>
      </div>
      <div>
        <div className="grid grid-cols-3 grid-rows-auto flex flex-col h-full sm:mt-10">
          {qualities()}
        </div>
        <div className="grid grid-cols-5 grid-rows-auto ">
          <p className="flex justify-end h-14 text-right bg-fifth text-black font-bold font-Poppins text-xs sm:text-md p-4 col-span-4 col-start-2 border-b border-b-2 border-l border-l-2 border-primary rounded-bl-lg">
            {payment}
          </p>
        </div>
      </div>
    </div>
  );
};

export default QualityReference;
