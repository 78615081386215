import React from "react";
import config from "../assets/index.json";
import { GiMagnifyingGlass } from "react-icons/gi";
import { LiaFileContractSolid } from "react-icons/lia";
import { AiOutlineQuestionCircle } from "react-icons/ai";

const icons = {
  glassIcon: <GiMagnifyingGlass className="flex w-full h-full" />,
  orientationIcon: <AiOutlineQuestionCircle className="flex w-full h-full" />,
  commissionIcon: <LiaFileContractSolid className="flex w-full h-full" />,
};

const BePartner = () => {
  const { bePartner } = config;
  const { title, href, content } = bePartner;
  const benefits = () => {
    return content.map((items, index) => {
      return index % 2 === 0 ? (
        <div className="p-4 sm:p-10 h-[50vh] w-full" key={index}>
          <div className="grid grid-cols-2 grid-rows-auto h-full">
            <div className="flex flex-col w-full h-full justify-center items-center">
              <div className=" sm:mt-10 flex justify-center items-center ">
                <div className="h-10 w-10 sm:h-20 sm:w-20 text-tertiary">
                  {icons[items.icon]}
                </div>
              </div>

              <div>
                <b className="text-tertiary text-center font-Inter text-lg lg:text-2xl border-b border-b-2 border-tertiary sm:w-72">
                  {items.title}
                </b>
              </div>
            </div>
            <div className="w-full flex flex-col items-center justify-start bg-secondary rounded-lg h-full">
              <div className="flex justify-center items-center h-full">
                <p className="text-fourth text-center font-Poppins text-sm sm:text-xl font-center w-48 sm:w-80 p-4 sm:p-0">
                  {items.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-4 sm:p-10 h-[50vh] w-full" key={index}>
          <div className="grid grid-cols-2 grid-rows-auto h-full">
            <div className="w-full flex flex-col items-center justify-start bg-secondary rounded-lg h-full">
              <div className="flex justify-center items-center h-full">
                <p className="text-fourth text-center font-Poppins text-sm sm:text-xl font-center w-48 sm:w-80 p-4 sm:p-0">
                  {items.description}
                </p>
              </div>
            </div>
            <div className="flex flex-col w-full h-full justify-center items-center">
              {items.button ? (
                <a
                  href={items.href}
                  target="_blank"
                  rel="noreferrer"
                  className="w-3/4 sm:w-1/2 h-14 rounded-full sm:mt-10 bg-tertiary no-underline"
                >
                  <div className="flex justify-center items-center h-full">
                    <p className="text-white font-bold font-Inter text-sm sm:text-xl text-center">
                      {items.title}
                    </p>
                  </div>
                </a>
              ) : (
                <div className="flex flex-col w-full h-full justify-center items-center">
                  <div className=" sm:mt-10 flex justify-center items-center ">
                    <div className="h-10 w-10 sm:h-20 sm:w-20 text-tertiary">
                      {icons[items.icon]}
                    </div>
                  </div>
                  <div>
                    <b className="text-tertiary text-center font-Inter text-lg sm:text-2xl border-b border-b-2 border-tertiary sm:w-72">
                      {items.title}
                    </b>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="w-full h-full bg-white flex flex-col" name={href}>
      <div className="flex justify-center items-center w-full h-20">
        <b className="h-8 sm:h-14 text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
          {title}
        </b>
      </div>
      <div>
        <div className="flex flex-col h-full w-full sm:mt-10">{benefits()}</div>
      </div>
    </div>
  );
};

export default BePartner;
