import React, { useState, useEffect } from "react";
import config from "../assets/index.json";
import logoImage from "../assets/svg/triunfo-logo.svg";
import { Popover, Transition } from "@headlessui/react";
import { FaBars } from "react-icons/fa6";

import { Link } from "react-scroll";

const icons = {
  cellphoneIcon: require("../assets/svg/cellphone-icon.svg").default,
  mailIcon: require("../assets/svg/mail-icon.svg").default,
  bars: <FaBars className="flex w-full h-full" />,
};

const contacts = (contactList) => {
  return (
    <div className="flex flex-row items-center justify-start">
      {contactList.map((items) => {
        return (
          <div
            key={items.icon}
            className="flex flex-row w-full grid grid-rows-auto grid-cols-4 md:grid-cols-3"
          >
            <div className="w-full flex justify-end">
              <img src={icons[items.icon]} alt={items.icon} />
            </div>
            <div className="col-span-2 w-full">
              <p className="text-fourth font-Poppins font-semibold ml-2 text-xs lg:text-md">
                {items.contact}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Header = () => {
  const { office, header } = config;
  const { name } = office;
  const { navigation, contactList } = header;
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setHidden(prevScrollPos < currentScrollPos);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pageScroll = (pageView) => {
    let currentScrollPos = window.scrollY;
    let viewportHeight = window.innerHeight;

    if (pageView === "home") {
      viewportHeight = window.innerHeight;
    } else if (pageView === "aboutUs") {
      viewportHeight = window.innerHeight * 3;
    } else if (pageView === "qualityReference") {
      viewportHeight = window.innerHeight * 6;
    } else if (pageView === "bePartner") {
      viewportHeight = window.innerHeight * 8;
    } else if (pageView === "aboutUs") {
      viewportHeight = window.innerHeight * 6;
    }

    if (viewportHeight > currentScrollPos) {
      return 0;
    } else {
      return -90;
    }
  };

  const headerTheme = () => {
    const viewportHeight = window.innerHeight;

    return window.scrollY <= viewportHeight / 2
      ? `fixed z-10 top-0 left-0 w-full text-light transition-transform ${
          hidden ? "-translate-y-full" : "translate-y-0 "
        }`
      : `fixed z-10 top-0 left-0 w-full text-light transition-transform bg-tertiary pb-2 border-b border-b-1 border-fourth ${
          hidden ? "-translate-y-full" : "translate-y-0 "
        }`;
  };

  return (
    <header
      className={`fixed z-10 top-0 left-0 w-full text-light transition-transform bg-tertiary pb-2 border-b border-b-1 border-fourth sm:${headerTheme()} h-20 sm:h-32`}
    >
      <Popover className="relative">
        <div className="grid grid-rows-1 grid-cols-2 sm:grid-cols-2 flex sm:ml-10">
          <div className=" flex bg-tertiary rounded-b-md w-40 sm:w-80 justify-center">
            <div className="flex flex-row h-full md:w-48 w-28 sm:w-20">
              <div className="w-full h-full">
                <img src={logoImage} alt={name} />
              </div>
            </div>
          </div>
          <Popover.Button
            className={
              "visible sm:invisible relative sm:absolute flex justify-end items-center p-4"
            }
          >
            <div className="h-10 w-10 text-white">{icons.bars}</div>
          </Popover.Button>
          <div className="sm:visible invisible sm:relative absolute">
            <div className="w-full h-full justify-center sm:grid sm:grid-rows-2">
              <div className="flex w-full h-full invisible sm:visible absolute sm:relative">
                {contacts(contactList)}
              </div>
              <div className="flex space-x-3 md:space-x-8 sm:font-bold font-Poppins h-full items-center justify-end sm:justify-center col-span-2 sm:col-span-1">
                {navigation.map((item) => {
                  return (
                    <Link
                      spy={true}
                      active="active"
                      offset={pageScroll(item.href)}
                      smooth={true}
                      duration={1000}
                      key={item.name}
                      to={item.href}
                      className="text-xs md:text-lg bg-tertiary hover:bg-[#5C5C5C] p-1 rounded-md cursor-pointer"
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <Popover.Panel
            focus
            className="absolute z-10 top-0 left-1/2 inset-x-0 p-2 transition transform origin-top-right w-1/2 h-full"
          >
            <div
              className={`rounded-lg shadow-md bg-background ring-1 ring-black ring-opacity-5 overflow-hidden`}
            >
              <div className="px-2 pt-2 flex items-center justify-end w-full">
                <div className="mr-2">
                  <Popover.Button
                    className={` rounded-md p-1 inline-flex bg-secondary`}
                  >
                    <span className="sr-only">Fechar menu</span>
                    <p className="h-6 w-6 font-bold" aria-hidden="true">
                      X
                    </p>
                  </Popover.Button>
                </div>
              </div>
              <div className="px-2 py-2 space-y-1">
                {navigation.map((item) => (
                  <Link
                    spy={true}
                    active="active"
                    smooth={true}
                    duration={1000}
                    key={item.name}
                    to={item.href}
                    className="block px-3 py-2 rounded-md text-sm bg-tertiary font-bold hover:bg-[#5C5C5C] p-1 cursor-pointer"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </div>
      </Popover>
    </header>
  );
};

export default Header;
