import React, { createRef, useState } from "react";
import emailjs from "@emailjs/browser";
import config from "../assets/index.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import formatNumber from "../utils/FormatNumber";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SERVICE_ID = "service_nk0iiyb";
const TEMPLATE_ID = "template_7pwlvsd";
const PUBLIC_KEY = "kFt-3Z8I0sTCSQXCD";

const images = {
  patentHomeImg: require("../assets/svg/patent-home-image.svg").default,
  brandHomeImg: require("../assets/svg/brand-home-image.svg").default,
};

function Root() {
  const { home } = config;
  const { formData, services, href, toastMessages } = home;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [onFocus, setOnFocus] = useState(false);

  const [formRefs, setFormRefs] = useState(
    Array(services.length)
      .fill(null)
      .map(() => createRef())
  );

  const sendEmail = (e, index) => {
    e.preventDefault();

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, formRefs[index].current, PUBLIC_KEY)
      .then(
        (result) => {
          console.log(result.text);
          toast.success(toastMessages.success);
          setPhoneNumber("");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          toast.error(toastMessages.fail);
          setPhoneNumber("");
          e.target.reset();
        }
      );
  };

  const swiperComponents = () => {
    return services.map((servicesData, index) => {
      return (
        <SwiperSlide
          className="flex h-[100vh] w-full bg-no-repeat bg-cover bg-left "
          style={{ backgroundImage: `url(${images[servicesData.bgImg]})` }}
          key={index}
        >
          <div className="flex w-full h-full grid grid-cols-2">
            <div className="flex h-full justify-center items-center flex-col p-2 w-full">
              <p
                className={
                  index % 2 === 0
                    ? "text-white sm:text-black font-bold font-Inter text-4xl sm:text-6xl"
                    : "text-white sm:text-white font-bold font-Inter text-4xl sm:text-6xl"
                }
              >
                {servicesData.title}
              </p>
              <br />
              <p
                className={
                  index % 2 === 0
                    ? "font-Poppins text-white sm:text-black font-bold text-sm sm:text-lg"
                    : "font-Poppins text-white sm:text-white font-bold text-sm sm:text-lg"
                }
              >
                {servicesData.descriptionEmphasis}
              </p>
              <br />
              <p
                className={
                  index % 2 === 0
                    ? "font-Poppins text-white sm:text-black text-xs sm:text-md text-center w-3/4 font-bold"
                    : "font-Poppins text-white sm:text-white text-xs sm:text-md text-center w-3/4 font-bold"
                }
              >
                {servicesData.description}
              </p>
              <a
                href={formData.href}
                target="_blank"
                rel="noreferrer"
                className="w-4/5 sm:w-1/2 h-10 sm:h-14 rounded-full mt-10 bg-tertiary no-underline"
              >
                <div className="flex justify-center items-center h-full">
                  <p className="text-center text-white font-bold font-Inter text-xs sm:text-xl">
                    {formData.callSpecialist}
                  </p>
                </div>
              </a>
            </div>
            <div className="flex w-full h-full justify-center items-center flex-col p-0 sm:p-4">
              <form
                className="bg-tertiary w-full sm:w-3/4 flex justify-center rounded-[25px] flex-col"
                onSubmit={(e) => sendEmail(e, index)}
                ref={formRefs[index]}
                onFocus={() => setOnFocus(true)}
                onBlur={() => setOnFocus(false)}
              >
                <div className="w-full flex justify-center p-2">
                  <p className="font-Inter font-bold text-center text-xs sm:text-md">
                    {servicesData.contactUs}
                  </p>
                </div>
                <div className="flex border border-2 border-tertiary w-full h-8 sm:h-14 rounded-lg px-4 mt-4">
                  <input
                    className="w-full font-JacquesFrancois text-xs sm:text-md rounded-full px-4"
                    type="text"
                    required
                    name="user_name"
                    placeholder={formData.name}
                  />
                </div>
                <div className="flex border border-2 border-tertiary w-full h-8 sm:h-14 rounded-lg px-4 mt-4">
                  <input
                    className="w-full font-JacquesFrancois text-xs sm:text-md rounded-full px-4"
                    type="email"
                    required
                    name="user_email"
                    placeholder={formData.email}
                  />
                </div>
                <div className="flex border border-2 border-tertiary w-full h-8 sm:h-14 rounded-lg px-4 mt-4">
                  <input
                    className="w-full font-JacquesFrancois text-xs sm:text-md rounded-full px-4"
                    type="tel"
                    required
                    name="user_phone"
                    placeholder={formData.phone}
                    value={phoneNumber}
                    onChange={(e) =>
                      setPhoneNumber(formatNumber(e.target.value, "phone"))
                    }
                  />
                </div>
                <input
                  className="absolute invisible"
                  type="text"
                  name="user_type"
                  value={servicesData.type}
                />
                <div className="w-full p-2 sm:p-4">
                  <button
                    className="flex justify-center items-center w-full h-10 sm:h-14 rounded-full mt-6 bg-secondary"
                    type="submit"
                    value="Send"
                  >
                    <p className="text-white font-bold font-Inter text-xs sm:text-xl">
                      {formData.sendContact}
                    </p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };
  SwiperCore.use([Autoplay, Pagination, Navigation]);
  return (
    <Swiper
      className="flex h-[100vh] w-full z-0"
      spaceBetween={0}
      pagination={true}
      navigation={true}
      slidesPerView={1}
      loop={true}
      name={href}
      style={{ zIndex: 0 }}
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        pauseOnMouseEnter: true || onFocus,
        disableOnInteraction: true || onFocus,
      }}
    >
      {swiperComponents()}
    </Swiper>
  );
}

export default Root;
