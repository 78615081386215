import React from "react";
import config from "../assets/index.json";

const WhyRegister = () => {
  const { whyRegister } = config;
  const { title, href, content } = whyRegister;
  const motives = () => {
    return content.map((items, index) => {
      return (
        <div className="p-1 sm:p-2 h-[60vh] sm:h-[80vh]" key={items.title}>
          <div className="w-full flex flex-col items-center justify-start bg-secondary rounded-lg h-full">
            <b className="text-fourth text-center font-Inter text-sm lg:text-3xl border-b border-b-2 border-fourth sm:w-72 mt-4 sm:mt-10">
              {items.title}
            </b>
            <div className="mt-2 p-4 sm:p-10">
              <p className="text-fourth text-center font-Inter text-xs sm:text-lg sm:w-72">
                {items.description}
              </p>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="w-full h-full bg-white flex flex-col" name={href}>
      <div className="flex justify-center items-center w-full h-20">
        <b className="h-8 sm:h-14 text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
          {title}
        </b>
      </div>
      <div className="grid grid-rows-auto grid-cols-3 flex flex-col h-full sm:mt-10">
        {motives()}
      </div>
    </div>
  );
};

export default WhyRegister;
