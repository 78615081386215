import React from "react";
import config from "../assets/index.json";

const image = {
  harleyImg: require("../assets/svg/harleyImg.svg").default,
};

const AboutUs = () => {
  const { aboutUs } = config;
  const { title, description, img, href } = aboutUs;
  return (
    <div className="h-full w-full" name={href}>
      <div className="flex justify-center items-center w-full h-20">
        <b className="h-8 sm:h-14 text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
          {title}
        </b>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 3xl:grid-cols-5 grid-rows-auto">
        <div className="p-5 sm:p-20 3xl:p-20 sm:col-span-2 3xl:col-span-2 h-full flex items-center justify-center">
          <b className="font-Poppins text-md sm:text-xl lg:text-4xl">
            {description}
          </b>
        </div>
        <div className="sm:col-span-2 3xl:col-span-3 flex flex-row justify-end items-center h-full w-full">
          <img src={image[img]} className="h-full" alt={img} />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
