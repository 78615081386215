import React from "react";
import config from "../assets/index.json";

const icons = {
  logo: require("../assets/svg/triunfo-logoLight.svg").default,
  cellphoneIcon: require("../assets/svg/cellphone-icon.svg").default,
  mailIcon: require("../assets/svg/mail-icon.svg").default,
  linkedinIcon: require("../assets/svg/linkedin-icon.svg").default,
  instagramIcon: require("../assets/svg/instagram-icon.svg").default,
  whatsappIcon: require("../assets/svg/whatsapp-icon.svg").default,
  facebookIcon: require("../assets/svg/facebook-icon.svg").default,
};

const address = (logo, socialMedia) => {
  return (
    <div className="flex flex-row h-full w-full items-center justify-center p-2">
      <div>
        <div className="w-full flex">
          <img src={icons[logo]} alt="camilloAdv" />
        </div>
        <div className="flex flex-row h-full w-full items-center justify-around">
          {socialMedia.map((items) => {
            return (
              <div
                key={items.icon}
                className="flex items-center justify-center mt-2"
              >
                <a href={items.href} target="_black">
                  <img src={icons[items.icon]} alt={items.icon} />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const contacts = (contactList) => {
  return (
    <div className="flex flex-col h-full w-full items-center justify-center">
      {contactList.map((items) => {
        return (
          <div
            key={items.icon}
            className="flex flex-row w-full items-center justify-center grid grid-rows-auto grid-cols-4 md:grid-cols-3"
          >
            <div className="w-full flex justify-end">
              <img src={icons[items.icon]} alt={items.icon} />
            </div>
            <div className="col-span-3 md:col-span-2 w-full">
              <p className="text-fourth font-Poppins font-semibold ml-2 text-sm lg:text-md">
                {items.contact}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const addressInfo = (addressInfos, callSpecialist, whatsappHref) => {
  return (
    <div className="flex flex-col w-full sm:w-2/3 p-2 justify-center align-center h-full text-fourth font-Poppins font-semibold text-sm lg:text-md">
      <div className="h-full flex items-end justify-center w-full">
        <div className="flex w-3/4 sm:w-full">
          <a
            href={whatsappHref}
            target="_blank"
            rel="noreferrer"
            className="w-full h-14 rounded-full mt-4 sm:mt-10 bg-tertiary no-underline"
          >
            <div className="flex justify-center items-center h-full">
              <p className="text-white font-bold font-Inter text-sm sm:text-xl ">
                {callSpecialist}
              </p>
            </div>
          </a>
        </div>
      </div>
      <div className="h-full flex flex-col mt-4 text-center sm:text-justify">
        <p>{`${addressInfos.street}, ${addressInfos.number}`}</p>
        <p>{`${addressInfos.city}/${addressInfos.UF}`}</p>
        <p>{`${addressInfos.CEP}`}</p>
      </div>
    </div>
  );
};

const footer = () => {
  const { footer } = config;
  const {
    logo,
    addressInfos,
    contactList,
    socialMedia,
    href,
    callSpecialist,
    whatsappHref,
  } = footer;
  const currentYear = new Date().getFullYear();
  return (
    <footer className="h-[65vh] w-full bg-secondary mt-10" name={href}>
      <div className="flex w-full h-full grid grid-cols-auto grid-rows-auto sm:grid-cols-3 sm:grid-rows-auto">
        {address(logo, socialMedia)}
        {contacts(contactList)}
        {addressInfo(addressInfos, callSpecialist, whatsappHref)}
      </div>
      <div className="flex w-full h-10 border-t border-t-1 border-fourth bg-secondary items-center justify-center font-Poppins">
        <p className="text-fourth text-sm">
          {`©${currentYear} designed by `}
          <a
            className="text-fourth "
            target="_black"
            href="https://github.com/HugoDaudt"
          >
            {` @HugoDaudt`}
          </a>
        </p>
      </div>
    </footer>
  );
};

export default footer;
