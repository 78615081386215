import React, { useState } from "react";

import { motion } from "framer-motion";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import Cookies from "js-cookie";

Modal.setAppElement("#root");

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const ModalApp = ({ handleClose, handleOpen, validateCookies }) => {
  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    authorization: false,
  });

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");

    const formatted = cleaned.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");

    return formatted;
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatPhoneNumber(inputValue);
    setUserData((prevUserData) => ({
      ...prevUserData,
      phone: formattedValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://192.168.15.33:5000/login",
        userData
      );
      const jwtToken = response.data.token;

      await Cookies.set("jwtToken", jwtToken);
      validateCookies();
      handleClose();
    } catch (error) {
      console.error("Erro ao enviar para o backend:", error);
    }
  };

  const modalRender = () => {
    return (
      <section>
        <div className="flex w-full justify-end absolute">
          <AiOutlineClose className="h-8 w-8 mr-8" onClick={handleClose} />
        </div>
        <form onSubmit={handleSubmit}>
          <label className="flex justify-center items-center w-full h-20">
            <b className="h-8 sm:h-14 text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
              Insira seus dados:
            </b>
          </label>
          <div className="flex border border-1 border-tertiary w-full h-10 rounded-lg px-4 mt-2">
            <input
              className="w-full font-Poppins text-sm lg:text-lg border-0 border-transparent focus:outline-none"
              required
              type="text"
              placeholder={"Nome:"}
              value={userData.name}
              onChange={(e) =>
                setUserData((prevUserData) => ({
                  ...prevUserData,
                  name: e.target.value,
                }))
              }
            />
          </div>
          <div className="flex border border-1 border-tertiary w-full h-10 rounded-lg px-4 mt-2">
            <input
              className="w-full font-Poppins text-sm lg:text-lg border-0 border-transparent focus:outline-none"
              required
              pattern="^\(?\d{2}\)?\s?\d{5}-\d{4}$"
              type="tel"
              placeholder={"Celular:"}
              value={userData.phone}
              onChange={handlePhoneChange}
              title="Digite um número de telefone válido com 11 dígitos (apenas números): 41987654321"
            />
          </div>
          <div className="flex">
            <input
              className="w-4 font-Poppins text-sm lg:text-lg border-0 border-transparent focus:outline-none mr-2"
              type="checkbox"
              checked={userData.authorization} // Use checked em vez de value
              onChange={(e) =>
                setUserData((prevUserData) => ({
                  ...prevUserData,
                  authorization: e.target.checked, // Use e.target.checked para obter o estado do checkbox
                }))
              }
            />
            <p className="w-full font-Poppins">
              Aceito receber o contato de um advogado especializado em minha
              dúvida
            </p>
          </div>
          <motion.button
            className="bg-primary border border-1 border-tertiary w-full h-12 text-lg lg:text-2xl text-fourth font-Poppins font-bold rounded-lg mt-4"
            type="submit"
            whileHover={{ scale: 1.05 }}
          >
            Enviar dados
          </motion.button>
        </form>
      </section>
    );
  };

  return (
    <div onClick={handleClose}>
      <Modal
        isOpen={handleOpen}
        className="absolute w-full h-full bg-[#000000e1] flex justify-center items-center"
      >
        <motion.div
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="pb-8 px-8 bg-[#fff] rounded-lg lg:max-w-7xl flex items-center justify-center"
        >
          <div>{modalRender()}</div>
        </motion.div>
      </Modal>
    </div>
  );
};

export default ModalApp;
