import React from "react";
import config from "../assets/index.json";

const images = {
  evolabsLogo: require("../assets/clients/evolabsLogo.svg").default,
  evolimsLogo: require("../assets/clients/evolimsLogo.svg").default,
  operacaoLaserLogo: require("../assets/clients/operacaoLaserLogo.jpeg"),
  metroparLogo: require("../assets/clients/metroparLogo.png"),
  mrbLogo: require("../assets/clients/mrbLogo.svg").default,
  hiperLogo: require("../assets/clients/hiperLogo.png"),
};

const WhyRegister = () => {
  const { clients } = config;
  const { title, href, eachClient } = clients;
  const ourClients = () => {
    return eachClient.map((items) => {
      return (
        <div className="p-2 h-[40vh]" key={items.name}>
          <div className="w-full flex flex-col items-center justify-start bg-fifth rounded-lg h-full border border-1 border-primary">
            <b className="text-primary font-Cormorant text-lg sm:text-2xl border-b border-b-2 border-primary">
              {items.name}
            </b>
            <div className="flex justify-center items-center h-full w-full sm:h-60 sm:w-60">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center h-full w-full"
                href={items.link}
              >
                <img
                  src={images[items.logo]}
                  className="h-full w-full p-2"
                  alt={items.logo}
                  style={{ objectFit: "contain" }}
                />
              </a>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="w-full h-full bg-white flex flex-col" name={href}>
      <div className="flex justify-center items-center w-full h-20">
        <b className="h-8 sm:h-14 text-secondary font-Cormorant text-2xl sm:text-4xl border-b border-b-2 border-primary">
          {title}
        </b>
      </div>
      <div>
        <div className="grid grid-cols-2 grid-rows-auto sm:grid-cols-3 sm:grid-rows-auto flex flex-col h-full sm:mt-10">
          {ourClients()}
        </div>
      </div>
    </div>
  );
};

export default WhyRegister;
