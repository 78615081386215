const formatNumber = (number, format, locale) => {
  const onlyNumbers = String(number).replace(/[^0-9]/g, "");
  switch (format) {
    case "phone":
      if (onlyNumbers.length <= 2) {
        return onlyNumbers;
      } else if (onlyNumbers.length <= 6) {
        return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(2)}`;
      } else if (onlyNumbers.length <= 10) {
        return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(
          2,
          6
        )}-${onlyNumbers.slice(6)}`;
      } else {
        return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(
          2,
          7
        )}-${onlyNumbers.slice(7, 11)}`;
      }
    case "cep":
      return onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
    case "cpf":
      return onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    case "cnpj":
      if (onlyNumbers.length <= 2) {
        return onlyNumbers;
      } else if (onlyNumbers.length <= 5) {
        return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(2)}`;
      } else if (onlyNumbers.length <= 8) {
        return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
          2,
          5
        )}.${onlyNumbers.slice(5)}`;
      } else if (onlyNumbers.length <= 12) {
        return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
          2,
          5
        )}.${onlyNumbers.slice(5, 8)}/${onlyNumbers.slice(8)}`;
      } else {
        return `${onlyNumbers.slice(0, 2)}.${onlyNumbers.slice(
          2,
          5
        )}.${onlyNumbers.slice(5, 8)}/${onlyNumbers.slice(
          8,
          12
        )}-${onlyNumbers.slice(12, 14)}`;
      }
    case "money":
      const cleanedString = number.replace(/,/g, ".").replace(/[^\d.-]/g, "");
      return Number(cleanedString).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    default:
      return onlyNumbers;
  }
};

export default formatNumber;
